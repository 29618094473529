import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const freemiumDashboardPathOptions: TPathOptions = {
	apiKey: 'freemium_dashboard',
	path: ROUTES.FREEMIUM_DASHBOARD.path,
	label: 'home',
	iconName: 'house',
	menuOrder: 1,
	excludedFor: [],
	ownApp: false,
};

export default freemiumDashboardPathOptions;
