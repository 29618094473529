import { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';

export const RewardsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.REWARDS.path,
			element: lazy(() => import('./Rewards')),
			aclProps: {
				aclGrants: ['view:loyalty'],
			},
		},
	],
};
