import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '@app/types/types';

export default {
	apiKey: 'analytics',
	path: ROUTES.ANALYTICS.path,
	label: 'analytics',
	iconName: 'chartPie',
	menuOrder: 2,
	ownApp: false,
	exact: false,
} as TPathOptions;
