import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const freemiumTeamsPathOptions: TPathOptions = {
	apiKey: 'freemium_administration',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: ROUTES.FREEMIUM_TEAMS.path,
	label: 'teams',
	iconName: 'userGroup',
	excludedFor: [],
	subMenu: [
		{
			featureApiKey: 'freemium_administration:freemium_administration',
			label: 'administrators',
			path: ROUTES.FREEMIUM_TEAMS.ADMINISTRATORS.path,
		},
		{
			featureApiKey: 'freemium_administration:freemium_teams',
			label: 'team_members',
			path: ROUTES.FREEMIUM_TEAMS.TEAMS.path,
		},
	],
};

export default freemiumTeamsPathOptions;
