import { MagicLinkPathEntry } from '@app/MagicLinkLogin/pathEntry';
import { IModuleOptions } from '@app/types/types';
import { activityLogPathEntry } from '@modules/ActivityLog/pathEntry';
import { analyticsPathEntry } from '@modules/Analytics/pathEntry';
import ArticlesPathEntry from '@modules/Articles/pathEntry';
import { BaristasPathEntry } from '@modules/Baristas/pathEntry';
import { BranchesPathEntry } from '@modules/Branches/pathEntry';
import { BranchesAvailabilityPathEntry } from '@modules/BranchesAvailability/pathEntry';
import { ExportsPathEntry } from '@modules/Exports/pathEntry';
import { CardLinkSetupPathEntry } from '@modules/CardLink/pathEntry';
import { CardLinkActivityPathEntry } from '@modules/CardLinkActivity/pathEntry';
import { CardLinkDashboardPathEntry } from '@modules/CardLinkDashboard/pathEntry';
import { CampaignsPathEntry } from '@modules/Campaigns/pathEntry';
import { DashboardPathEntry } from '@modules/Dashboard/pathEntry';
import { FeedbackPathEntry } from '@modules/Feedback/pathEntry';
import { FreemiumDashboardPathEntry } from '@modules/FreemiumDashboard/pathEntry';
import { MenuPathEntry } from '@modules/Menu/pathEntry';
import { OrdersPathEntry } from '@modules/Orders/pathEntry';
import { OrderStatusHistoryPathEntry } from '@modules/OrderStatusHistory/pathEntry';
import { RecommendationsPathEntry } from '@modules/Recommendations/pathEntry';
import { RewardsPathEntry } from '@modules/Rewards/pathEntry';
import { settingsPathEntry } from '@modules/Settings/pathEntry';
import { StoriesPathEntry } from '@modules/Stories/pathEntry';
import { teamsPathEntry } from '@modules/TeamMembers/pathEntry';
import { tipsPathEntry } from '@modules/Tip/pathEntry';
import { topUpPathEntry } from '@modules/TopUp/pathEntry';
import { SubscriptionsPathEntry } from '@modules/Subscriptions/pathEntry';
import { TechniciansSlotsPathEntry } from '@modules/TechniciansSlots/pathEntry';
import { TerminalManagementPathEntry } from '@modules/TerminalManagement/pathEntry';
import { VisitTransactionsPathEntry } from '@modules/VisitTransactions/pathEntry';
import { WheelOfFortunePathEntry } from '@modules/WheelOfFortune/pathEntry';
import { FreemiumLoyaltyPathEntry } from '@modules/FreemiumLoyalty/pathEntry';
import { FreemiumTeamsPathEntry } from '@modules/FreemiumTeams/pathEntry';
import { GalleryPathEntry } from '@modules/Gallery/pathEntry';
import { GiftCardPathEntry } from '@modules/GiftCard/pathEntry';
import { GiftFriendPathEntry } from '@modules/GiftFriend/pathEntry';
import { PremiumDashboardPathEntry } from '@modules/PremiumDashboard/pathEntry';
import { PremiumTransactionsPathEntry } from '@modules/PremiumTransactions/pathEntry';
import { PromocodesPathEntry } from '@modules/Promocodes/pathEntry';
import { CustomersPathEntry } from '@modules/Records/pathEntry';
import { AnnouncementsPathEntry } from '@modules/Announcements/pathEntry';
import { ConsumerReportsPathEntry } from '@modules/ConsumerReport/pathEntry';

export const appPathEntries: IModuleOptions[] = [
	ArticlesPathEntry,
	FreemiumDashboardPathEntry,
	FreemiumLoyaltyPathEntry,
	PremiumDashboardPathEntry,
	OrdersPathEntry,
	MenuPathEntry,
	RecommendationsPathEntry,
	RewardsPathEntry,
	BaristasPathEntry,
	BranchesPathEntry,
	BranchesAvailabilityPathEntry,
	ExportsPathEntry,
	StoriesPathEntry,
	activityLogPathEntry,
	settingsPathEntry,
	OrderStatusHistoryPathEntry,
	tipsPathEntry,
	analyticsPathEntry,
	topUpPathEntry,
	teamsPathEntry,
	TerminalManagementPathEntry,
	TechniciansSlotsPathEntry,
	VisitTransactionsPathEntry,
	CardLinkDashboardPathEntry,
	SubscriptionsPathEntry,
	CardLinkSetupPathEntry,
	CardLinkActivityPathEntry,
	WheelOfFortunePathEntry,
	FreemiumTeamsPathEntry,
	PremiumTransactionsPathEntry,
	CampaignsPathEntry,
	DashboardPathEntry,
	FeedbackPathEntry,
	GiftCardPathEntry,
	GiftFriendPathEntry,
	GalleryPathEntry,
	PromocodesPathEntry,
	MagicLinkPathEntry,
	CustomersPathEntry,
	AnnouncementsPathEntry,
	ConsumerReportsPathEntry,
];
