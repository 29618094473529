import React, { memo } from 'react';
import { MenuBar } from '@zeal/web-ui';
import { useTranslation } from 'react-i18next';
import { IconNames } from '@zeal/web-ui/dist/Icon';
import { TPathOptions } from '../../types/types';
import { usePageStore } from '@app/store/pageStore';
import { useLocation } from 'react-router-dom';

const RouteMenuLink = ({ route }: { route: TPathOptions }) => {
	const { t } = useTranslation('sidedrawer');

	const { appMenu: appMenuState, toggleAppMenu } = usePageStore();

	useLocation();

	const isMenuCollapsed = appMenuState === 'minimized';

	if (route.subMenu && route.subMenu.length === 0) {
		return null;
	}

	if (route.subMenu && route.subMenu?.length === 1) {
		return (
			<MenuBar.Link
				isFeatured={route.subMenu[0].isFeatured}
				isCollapsed={isMenuCollapsed}
				exact
				key={route.subMenu[0].label}
				label={t(route.label)}
				id={route.subMenu[0].label.replace(/\s/g, '-')}
				to={route.subMenu[0].path}
				iconName={route.iconName as IconNames}
			/>
		);
	}

	if (route.subMenu && route.subMenu?.length > 1) {
		return (
			<MenuBar.SubMenu
				key={route.label}
				isCollapsed={isMenuCollapsed}
				toggleAppMenu={toggleAppMenu}
				label={t(route.label)}
				id={route.label.replace(/\s/g, '-')}
				iconName={route.iconName as IconNames}
				subMenu={route.subMenu}
				basePath={route.path}
			/>
		);
	}

	return (
		<MenuBar.Link
			isFeatured={route.isFeatured}
			isCollapsed={isMenuCollapsed}
			exact
			key={route.label}
			label={t(route.label)}
			id={route.label.replace(/\s/g, '-')}
			to={route.path}
			iconName={route.iconName as IconNames}
		/>
	);
};

export default memo(RouteMenuLink);
