import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Grid } from '@zeal/web-ui';

import { usePageConfig } from '@app/hooks/usePageConfig';

import SettingsMenuCard from './data/MenuMap';

export default function SettingsFrame(props: React.PropsWithChildren<{}>) {
	const { t } = useTranslation('settings');

	usePageConfig({ title: t('settings') });

	return (
		<Card m="md" p="none">
			<Grid
				cols={6}
				gap="md"
				className="[grid-template-columns: minmax(250px, 1fr) repeat(5, 1fr)]"
			>
				<div className="h-full col-span-1 p-4 border-r-2 border-gray-100 min-h-screen">
					{SettingsMenuCard}
				</div>
				<div className="h-full col-span-5 px-6 py-4">{props.children}</div>
			</Grid>
		</Card>
	);
}
