import React from 'react';
import { Label, Padding } from '@zeal/web-ui';
import { useTranslation } from 'react-i18next';

export const MenuLinksSection = ({
	children,
	title,
	isCollapsed,
}: {
	title: string;
	children: React.ReactNode;
	isCollapsed?: boolean;
}) => {
	const { t } = useTranslation('sidedrawer');

	return (
		<>
			<Padding padding={{ horizontal: '4', vertical: '2' }}>
				<Label.Big100 variant="subdued" className="truncate block w-full">
					{!isCollapsed && t(title)}
				</Label.Big100>
			</Padding>
			{children}
		</>
	);
};
